import RouteLink from '@components/RouteLink'
import TableNameCard from '@components/TableNameCard'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { yoloManufactureLogo } from 'yolo-design/assets'
import { Button, FormSelect } from 'yolo-design/components'
import DispatchDetails from './DispatchDetails'
import {
  useChangeDispatchStatus,
  useCreateDispatchData,
  useDeleteDispatch,
  useDeleteDispatchItem,
  useDispatchData,
  useWorkOrderSearchList,
} from '@services/dispatch'
import { imgPlaceholder } from '@assets/images'
import { useGetNumber } from '@services/master'
import { Form, Popconfirm, Skeleton } from 'antd'
import { Check, Trash } from 'yolo-design/icons'
import { Color } from 'yolo-design/constants'
import { useReactToPrint } from 'react-to-print'
import useDebounce from '@constants/index'

const DispatchView = () => {
  const [jobSearch, setJobSearch] = useState('')
  const [workOrderList, setWorkOrderList] = useState<any>()

  const [printLabelData, setPrintLabelData] = useState()

  const { orgId } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [searchParams, setSearchParams] = useSearchParams()
  const jobId = searchParams.get('work')
  const DispatchId = searchParams.get('dispatchId')
  const isView = searchParams.get('view')
  const searchDebounced = useDebounce(jobSearch, 500)

  const printRef = useRef(null)
  const barCodeRef = useRef(null)

  const handlePrint = useReactToPrint({
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    content: () => printRef.current,
  })
  const handlePrintBarcode = useReactToPrint({
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    content: () => barCodeRef.current,
  })
  const {
    data: jobSearchList,
    isLoading: jobLoading,
    refetch: refetchWorkOrder,
  } = useWorkOrderSearchList(
    {
      onSuccess(data) {
        setWorkOrderList(data?.data)
      },
    },
    { search: searchDebounced }
  )

  const { data } = useGetNumber()

  const {
    data: dispatchData,
    refetch: dispatchRefetch,
    isLoading,
  } = useDispatchData(
    {
      enabled: !!DispatchId,
      onSuccess(data) {
        const length = data?.dispatch_items?.length

        const maxSlNo = length
          ? Math.max(
              ...(data?.dispatch_items?.map((item: any) =>
                Number(item.serial_no)
              ) || 0)
            ) + 1
          : 1

        form.setFieldValue('sl_no', maxSlNo ? maxSlNo : 1)
      },
    },
    { id: Number(DispatchId) }
  )

  const dispatchDetail = DispatchId
    ? { ...dispatchData }
    : {
        ...workOrderList?.find((job: any) => String(job?.id) === String(jobId)),
        dispatch_no: data?.Dispatch_number,
      }

  const changeStatus = useChangeDispatchStatus({
    onSuccess() {
      navigate(`/${orgId}/dispatch`)
    },
  })
  const createDispatchData = useCreateDispatchData({
    onSuccess: (res) => {
      if (!DispatchId) {
        searchParams?.set('dispatchId', res?.id)
        setSearchParams(searchParams)
      } else {
        dispatchRefetch()
      }
      const fieldInstance = form.getFieldInstance('tar')
      if (fieldInstance) {
        fieldInstance.focus()
      }
      form.resetFields(['sl_no', 'gross'])
    },
  })
  const deleteDispatchData = useDeleteDispatchItem({
    onSuccess: () => {
      dispatchRefetch()
    },
  })
  const deleteDispatch = useDeleteDispatch({
    onSuccess: () => {
      navigate(`/${orgId}/dispatch`)
    },
  })

  //   const splitTableIntoPages = () => {
  //     const rows = Array.from(printDispatchRef.current.querySelectorAll('tr'));
  //     const rowsPerPage = 30; // Set the number of rows per page
  //     const totalPages = Math.ceil(rows.length / rowsPerPage);
  // console.log(totalPages);

  //     // Create array to hold pages with table rows
  //     const tablePages = [];
  //     for (let i = 0; i < totalPages; i++) {
  //       const start = i * rowsPerPage;
  //       const end = start + rowsPerPage;
  //       tablePages.push({
  //         rows: rows.slice(start, end),
  //         isLastPage: i === totalPages - 1,
  //       });
  //     }

  //     setPages(tablePages)
  //   };

  //   console.log(pages);

  // const items: any = [
  //   {
  //     label: (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           handlePrintDispatchDetail()
  //         }}
  //       >
  //         <Text value={'Packing Print'} color={Color?.blue?.just_blue} />
  //       </div>
  //     ),
  //     key: '1',
  //   },
  //   dispatchDetail?.enable_packing
  //     ? {
  //         label: (
  //           <div
  //             className="cursor-pointer"
  //             onClick={() => {
  //               handlePrintPouchDispatchDetail()
  //             }}
  //           >
  //             <Text value={'Pouch Print'} color={Color?.blue?.just_blue} />
  //           </div>
  //         ),
  //         key: '2',
  //       }
  //     : null,
  // ]

  useEffect(() => {
    if (DispatchId) {
      dispatchRefetch()
      // splitTableIntoPages()
    }
  }, [])

  useEffect(() => {
    refetchWorkOrder()
  }, [searchDebounced])

  return (
    <div className="d-flex w-100 flex-column">
      {isLoading && DispatchId ? (
        <Skeleton active />
      ) : (
        <>
          <div className="d-flex w-100 justify-content-between gap-5 border-bottom border-grey-200 align-items-center py-4 px-24">
            <RouteLink to={'dispatch'} addOrg>
              <img
                width={230}
                height={35}
                src={yoloManufactureLogo}
                alt="inventory"
              />
            </RouteLink>
            {isView === 'view' || dispatchDetail?.dispatch_items?.length ? (
              <></>
            ) : (
              <>
                {!jobId ? (
                  <>
                    <FormSelect
                      required
                      name={'job'}
                      showErrorMessage={true}
                      andtFormItemProps={{
                        className: 'w-50 ',
                        preserve: false,
                      }}
                      antdSelectProps={{
                        searchValue: jobSearch,
                        // allowClear:true,
                        style: {
                          marginBottom: '-16px',
                          marginTop: '2px',
                        },
                        onSearch: (val) => {
                          // setWorkOrderList([])

                          setJobSearch(val)
                        },
                        loading: jobLoading,
                        showSearch: true,
                        optionLabelProp: 'name',
                        onSelect: (key) => {
                          searchParams.set('work', key)
                          setSearchParams(searchParams)
                        },
                        filterOption: false,
                      }}
                      optionsSelect={jobSearchList?.data?.map((el) => ({
                        ...el,
                        name: el?.job_name,
                        value: el?.id,
                        label: (
                          <div className="border-bottom border-grey-200 ">
                            <TableNameCard
                              image={el?.job_image || imgPlaceholder}
                              title={el?.job_name}
                              subTitle1={`Code: ${el?.job_code}`}
                              subTitle2={`Work Order No: ${el?.work_order_no}`}
                            />
                          </div>
                        ),
                      }))}
                      placeHolder="Enter Job Name"
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}

            <div className="d-flex gap-2">
              {String(dispatchDetail?.dispatch_status) === '1' ? (
                <>
                  <Popconfirm
                    placement="leftTop"
                    style={{ width: '200px' }}
                    // okText={}

                    cancelText="Cancel"
                    okButtonProps={{
                      loading: deleteDispatch?.isLoading,
                      style: {
                        borderColor: Color?.indicators?.error,
                        backgroundColor: 'transparent',
                        color: Color?.indicators?.error,
                      },
                    }}
                    title="Are you sure to delete this dispatch?"
                    onConfirm={() => {
                      deleteDispatch?.mutate({ data: { id: DispatchId } })
                    }}
                    icon={false}
                  >
                    <Button
                      label="Delete"
                      icon={Trash}
                      antdButtonProps={{
                        loading: deleteDispatch?.isLoading,
                      }}
                      type="outlined"
                      borderColor={Color?.indicators?.error}
                      color={Color?.indicators?.error}
                    />
                  </Popconfirm>

                  <Button
                    label="Close"
                    antdButtonProps={{
                      onClick: () => {
                        navigate(`/${orgId}/dispatch`)
                      },
                    }}
                    type="outlined"
                  />
                  <Button
                    antdButtonProps={{
                      loading: changeStatus?.isLoading,
                      onClick: () => {
                        changeStatus?.mutate({
                          data: {
                            dispatch_status: '2',
                            id: DispatchId,
                            dispatch_date: form.getFieldValue('dispatch_date'),
                          },
                        })
                      },
                    }}
                    label="Save"
                  />
                </>
              ) : String(dispatchDetail?.dispatch_status) === '2' ? (
                <>
                  <Button
                    label="Close"
                    antdButtonProps={{
                      onClick: () => {
                        navigate(`/${orgId}/dispatch`)
                      },
                    }}
                    type="outlined"
                  />
                  <Popconfirm
                    placement="leftTop"
                    style={{ width: '200px' }}
                    // okText={}

                    cancelText="Cancel"
                    okButtonProps={{
                      loading: deleteDispatch?.isLoading,
                      style: {
                        borderColor: Color?.indicators?.error,
                        backgroundColor: 'transparent',
                        color: Color?.indicators?.error,
                      },
                    }}
                    title="Are you sure to delete this dispatch?"
                    onConfirm={() => {
                      deleteDispatch?.mutate({ data: { id: DispatchId } })
                    }}
                    icon={false}
                  >
                    <Button
                      label="Delete"
                      icon={Trash}
                      antdButtonProps={{
                        loading: deleteDispatch?.isLoading,
                      }}
                      type="outlined"
                      borderColor={Color?.indicators?.error}
                      color={Color?.indicators?.error}
                    />
                  </Popconfirm>
                  <Button
                    label="Save as Complete"
                    icon={Check}
                    antdButtonProps={{
                      loading: changeStatus?.isLoading,
                      onClick: () => {
                        changeStatus?.mutate({
                          data: {
                            dispatch_status: '3',
                            id: DispatchId,
                            dispatch_date: form.getFieldValue('dispatch_date'),
                          },
                        })
                      },
                    }}
                    type="outlined"
                  />
                  <Button
                    antdButtonProps={{
                      loading: changeStatus?.isLoading,
                      onClick: () => {
                        changeStatus?.mutate({
                          data: {
                            dispatch_status: '2',
                            id: DispatchId,
                            dispatch_date: form.getFieldValue('dispatch_date'),
                          },
                        })
                      },
                    }}
                    label="Save"
                  />
                  {/* <Button
                    antdButtonProps={{ style: { height: '40px' } }}
                    label="Report"
                    variant="dropdown"
                    size="extra_small"
                    type="outlined"
                    dropDownProps={{
                      menu: {
                        items: items,
                      },

                      trigger: ['click', 'contextMenu'],
                    }}
                  /> */}
                </>
              ) : String(dispatchDetail?.dispatch_status) === '3' ? (
                <>
                  <Popconfirm
                    placement="leftTop"
                    style={{ width: '200px' }}
                    // okText={}

                    cancelText="Cancel"
                    okButtonProps={{
                      loading: deleteDispatch?.isLoading,
                      style: {
                        borderColor: Color?.indicators?.error,
                        backgroundColor: 'transparent',
                        color: Color?.indicators?.error,
                      },
                    }}
                    title="Are you sure to delete this dispatch?"
                    onConfirm={() => {
                      deleteDispatch?.mutate({ data: { id: DispatchId } })
                    }}
                    icon={false}
                  >
                    <Button
                      label="Delete"
                      icon={Trash}
                      antdButtonProps={{
                        loading: deleteDispatch?.isLoading,
                      }}
                      type="outlined"
                      borderColor={Color?.indicators?.error}
                      color={Color?.indicators?.error}
                    />
                  </Popconfirm>
                  <Button
                    label="Close"
                    antdButtonProps={{
                      onClick: () => {
                        navigate(`/${orgId}/dispatch`)
                      },
                    }}
                    type="outlined"
                  />
                  {/* <Button
                    antdButtonProps={{ style: { height: '40px' } }}
                    label="Report"
                    variant="dropdown"
                    size="extra_small"
                    type="outlined"
                    dropDownProps={{
                      menu: {
                        items: items,
                      },

                      trigger: ['click', 'contextMenu'],
                    }}
                  /> */}
                </>
              ) : (
                <>
                  <Button
                    label="Close"
                    antdButtonProps={{
                      onClick: () => {
                        navigate(`/${orgId}/dispatch`)
                      },
                    }}
                    type="outlined"
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
      {isLoading && DispatchId ? (
        <>
          <Skeleton className="mt-32" active />
        </>
      ) : (
        <>
          <div className="p-24">
            {DispatchId ? (
              <DispatchDetails
                setWorkOrderList={setWorkOrderList}
                setPrintLabelData={setPrintLabelData}
                printLabelData={printLabelData}
                handlePrint={handlePrint}
                handlePrintBarcode={handlePrintBarcode}
                barcodeLabel={barCodeRef}
                printLabel={printRef}
                createDispatchData={createDispatchData}
                deleteDispatchData={deleteDispatchData}
                form={form}
                dispatchDetail={dispatchDetail}
              />
            ) : (
              dispatchDetail?.job_id && (
                <DispatchDetails
                  setWorkOrderList={setWorkOrderList}
                  setPrintLabelData={setPrintLabelData}
                  handlePrintBarcode={handlePrintBarcode}
                  barcodeLabel={barCodeRef}
                  printLabelData={printLabelData}
                  handlePrint={handlePrint}
                  printLabel={printRef}
                  createDispatchData={createDispatchData}
                  deleteDispatchData={deleteDispatchData}
                  form={form}
                  dispatchDetail={dispatchDetail}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default DispatchView
