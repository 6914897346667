import React, { useEffect, useState } from 'react'
import { imagePlaceholder } from 'yolo-design/assets'
import {
  Button,
  FormInput,
  FormWrapper,
  Text,
  FormInputDate as YoloDate,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import PouchCreateTable from '../Table/PouchCreateTable'

import PouchTable from '../Table/PouchTable'

import dayjs from 'dayjs'
import { Sync } from 'yolo-design/icons'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { queries } from '@constants/queryKeys'

const DispatchDetails = ({
  printLabel,
  printLabelData,
  setPrintLabelData,
  handlePrint,
  dispatchDetail,
  form,
  createDispatchData,
  deleteDispatchData,
  setWorkOrderList,
  handlePrintBarcode,
  barcodeLabel,
}: {
  handlePrint: any
  setPrintLabelData: any
  printLabelData: any
  printLabel: any
  dispatchDetail: any
  form: any
  createDispatchData: any
  deleteDispatchData: any
  setWorkOrderList: any
  handlePrintBarcode: any
  barcodeLabel: any
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const [isHavePouchForm, setIsHavePouchForm] = useState(false)
  const dispatchId = searchParams?.get('dispatchId')
  const isCompletedDispatch = String(dispatchDetail?.dispatch_status) === '3'

  useEffect(() => {
    form.setFieldValue('dispatch_no', dispatchDetail?.dispatch_no)
    form.setFieldValue('work_order_no', dispatchDetail?.work_order_no)
    form.setFieldValue(
      'dispatch_date',
      dayjs(dispatchDetail?.dispatch_date) || dayjs()
    )
    form.setFieldValue('notes', dispatchDetail?.notes || '')
    setIsHavePouchForm(!!dispatchDetail?.enable_packing)
  }, [dispatchDetail])

  return (
    <div>
      <div className="d-flex w-100 flex-column">
        {/* WorkOreder Title */}
        <div className="d-flex justify-content-between pb-12 border-bottom border-grey-300  w-100 ">
          <div className="d-flex w-75 gap-3">
            <div>
              <img
                height={132}
                width={103}
                alt="workorder-image"
                className="object-fit-contain p-2 border border-2 rounded-12 border-grey-200"
                src={dispatchDetail?.job_image || imagePlaceholder}
              />
            </div>
            <div className="d-flex w-100 justify-content-between">
              <div className="d-flex flex-column justify-content-between ">
                <div>
                  <div className="d-flex  align-items-center">
                    <Text
                      category="heading"
                      size="small"
                      value={`${dispatchDetail?.job_name}`}
                      weight="semibold"
                    />
                  </div>

                  <div className="d-flex gap-3">
                    <div className="d-flex gap-1  align-items-center">
                      <Text
                        category="label"
                        size="small"
                        value={'Job Code:'}
                        weight="medium"
                        color={Color.gray_shades.gray_600}
                      />
                      <Text
                        category="label"
                        size="small"
                        value={dispatchDetail?.job_code}
                        color={Color.gray_shades.gray_600}
                        weight="medium"
                      />
                    </div>
                    <div className="d-flex gap-1 align-items-center">
                      <Text
                        category="label"
                        size="small"
                        value={'Status:'}
                        weight="medium"
                        color={Color.gray_shades.gray_600}
                      />
                      <div
                        className="spacer"
                        style={{
                          width: 'max-content',
                          backgroundColor: dispatchDetail?.color_code
                            ? dispatchDetail?.color_code
                            : Color?.blue?.washed_blue,
                          borderRadius: '4px',
                          padding: '2px 4px',
                          fontSize: '12px',
                        }}
                      >
                        {dispatchDetail?.dispatch_status_name
                          ? dispatchDetail?.dispatch_status_name
                          : 'New'}
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <FormWrapper antdFormProps={{ form: form }}>
                    <div className="d-flex  gap-2">
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <Text
                            category="label"
                            size="small"
                            weight="medium"
                            value="Work Order No"
                            className="mb-4"
                          />
                          <FormInput
                            showErrorMessage={false}
                            name={'work_order_no'}
                            readOnly
                            value={dispatchDetail?.dispatch_no}
                            antdInputProps={{
                              form: form,
                              style: {
                                marginBottom: '-16px',
                              },
                              defaultValue: dispatchDetail?.dispatch_no,
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <Text
                            category="label"
                            size="small"
                            weight="medium"
                            value="Dispatch No"
                            className="mb-4"
                          />
                          <FormInput
                            showErrorMessage={false}
                            name={'dispatch_no'}
                            readOnly
                            value={dispatchDetail?.dispatch_no}
                            antdInputProps={{
                              form: form,
                              style: {
                                marginBottom: '-16px',
                              },
                              defaultValue: dispatchDetail?.dispatch_no,
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ width: '200px' }}>
                        <Text
                          category="label"
                          size="small"
                          weight="medium"
                          value="Dispatch Date"
                          className="mb-4"
                        />
                        <YoloDate
                          antdInputProps={{
                            style: {
                              marginBottom: '-16px',
                            },
                          }}
                          antdDatePickerProps={{
                            defaultValue: dayjs(),

                            disabled: isCompletedDispatch,
                            style: { width: '100%', marginBottom: '-16px' },
                            format: 'D-MMM-YYYY ',
                          }}
                          andtFormItemProps={{
                            validateTrigger: 'onChange',
                          }}
                          name="dispatch_date"
                          required={true}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <Text
                            category="label"
                            size="small"
                            weight="medium"
                            value="Notes"
                            className="mb-4"
                          />
                          <FormInput
                            showErrorMessage={false}
                            name={'notes'}
                            antdInputProps={{
                              form: form,
                              style: {
                                marginBottom: '-16px',
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </FormWrapper>
                </>
              </div>
              <Button
                label="Change"
                type="ghost"
                icon={Sync}
                style={{ marginRight: '12px' }}
                antdButtonProps={{
                  onClick: () => {
                    setWorkOrderList([])
                    queryClient.invalidateQueries({
                      queryKey: queries?.dispatch?.work_by_id({}).queryKey,
                    })
                    searchParams?.delete('work')
                    setSearchParams(searchParams)
                  },
                  disabled: !!dispatchId,
                }}
              />
            </div>
          </div>
          <div className="border-start ps-12 w-25 border-grey-300">
            <div className="d-flex flex-column justify-content-between ">
              <div className="d-flex mb-8 gap-5">
                <div className="d-flex border-start ps-16 border-grey-200 flex-column">
                  <Text
                    category="label"
                    size="small"
                    value={'Output Qty'}
                    weight="medium"
                    color={Color.gray_shades.gray_600}
                  />
                  <Text
                    category="title"
                    size="small"
                    value={`${dispatchDetail?.output_quantity}(${dispatchDetail?.unit_name})`}
                    weight="medium"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create Table */}
        <div className="mt-16">
          {dispatchDetail?.dispatch_status === 3 ? (
            <></>
          ) : (
            <>
              <>
                <PouchCreateTable
                  setPrintLabelData={setPrintLabelData}
                  printLabelData={printLabelData}
                  printFunc={handlePrint}
                  form={form}
                  createDispatchData={createDispatchData}
                  isPouch={isHavePouchForm}
                  dispatch_detail={dispatchDetail}
                />
              </>
            </>
          )}
        </div>
        {/* List Table  */}
        <div className="mt-24">
          <>
            <PouchTable
              isHavePouchForm={isHavePouchForm}
              setIsHavePouchForm={setIsHavePouchForm}
              setPrintLabelData={setPrintLabelData}
              printLabelData={printLabelData}
              printRef={printLabel}
              printFunc={handlePrint}
              barcodeFun={handlePrintBarcode}
              barcodeRef={barcodeLabel}
              dispatch_detail={dispatchDetail}
              deleteDispatchData={deleteDispatchData}
              isPouch={isHavePouchForm}
              form={form}
            />
          </>
        </div>
      </div>
    </div>
  )
}

export default DispatchDetails

// [
//   [
//       0,
//       1,
//       2,
//       3,
//       4,
//       5,
//       6,
//       7,
//       8,
//       9,
//       10,
//       11,
//       12,
//       13,
//       14,
//       15,
//       16,
//       17,
//       18,
//       19,
//       20,
//       21,
//       22,
//       23,
//       24,
//       25,
//       26,
//       27,
//       28,
//       29,
//       30,
//       31,
//       32,
//       99,
//       100,
//       101,
//       102,
//       103,
//       104,
//       105,
//       106,
//       107,
//       108,
//       109,
//       110,
//       111,
//       112,
//       113,
//       114,
//       115,
//       116,
//       117,
//       118,
//       119,
//       120,
//       121,
//       122,
//       123,
//       124,
//       125,
//       126,
//       127,
//       128,
//       129,
//       130,
//       131,
//       198,
//       199,
//       200,
//       201,
//       202,
//       203,
//       204,
//       205,
//       206,
//       207,
//       208,
//       209,
//       210,
//       211,
//       212,
//       213,
//       214,
//       215,
//       216,
//       217,
//       218,
//       219,
//       220,
//       221,
//       222,
//       223,
//       224,
//       225,
//       226,
//       227,
//       228,
//       229,
//       230,
//       297,
//       298,
//       299
//   ],
//   [
//       33,
//       34,
//       35,
//       36,
//       37,
//       38,
//       39,
//       40,
//       41,
//       42,
//       43,
//       44,
//       45,
//       46,
//       47,
//       48,
//       49,
//       50,
//       51,
//       52,
//       53,
//       54,
//       55,
//       56,
//       57,
//       58,
//       59,
//       60,
//       61,
//       62,
//       63,
//       64,
//       65,
//       132,
//       133,
//       134,
//       135,
//       136,
//       137,
//       138,
//       139,
//       140,
//       141,
//       142,
//       143,
//       144,
//       145,
//       146,
//       147,
//       148,
//       149,
//       150,
//       151,
//       152,
//       153,
//       154,
//       155,
//       156,
//       157,
//       158,
//       159,
//       160,
//       161,
//       162,
//       163,
//       164,
//       231,
//       232,
//       233,
//       234,
//       235,
//       236,
//       237,
//       238,
//       239,
//       240,
//       241,
//       242,
//       243,
//       244,
//       245,
//       246,
//       247,
//       248,
//       249,
//       250,
//       251,
//       252,
//       253,
//       254,
//       255,
//       256,
//       257,
//       258,
//       259,
//       260,
//       261,
//       262,
//       263
//   ],
//   [
//       66,
//       67,
//       68,
//       69,
//       70,
//       71,
//       72,
//       73,
//       74,
//       75,
//       76,
//       77,
//       78,
//       79,
//       80,
//       81,
//       82,
//       83,
//       84,
//       85,
//       86,
//       87,
//       88,
//       89,
//       90,
//       91,
//       92,
//       93,
//       94,
//       95,
//       96,
//       97,
//       98,
//       165,
//       166,
//       167,
//       168,
//       169,
//       170,
//       171,
//       172,
//       173,
//       174,
//       175,
//       176,
//       177,
//       178,
//       179,
//       180,
//       181,
//       182,
//       183,
//       184,
//       185,
//       186,
//       187,
//       188,
//       189,
//       190,
//       191,
//       192,
//       193,
//       194,
//       195,
//       196,
//       197,
//       264,
//       265,
//       266,
//       267,
//       268,
//       269,
//       270,
//       271,
//       272,
//       273,
//       274,
//       275,
//       276,
//       277,
//       278,
//       279,
//       280,
//       281,
//       282,
//       283,
//       284,
//       285,
//       286,
//       287,
//       288,
//       289,
//       290,
//       291,
//       292,
//       293,
//       294,
//       295,
//       296,
//   ]

// ]
