// import React from 'react'
import cn from 'classnames'
import styles from './print.module.scss'

import dayjs from 'dayjs'
import { businessAvatarFive } from 'yolo-design/assets'

const originalArray = [
  {
    sl_no: 1,
    gross_weight: 50.888,
    tare_weight: 56.97,
    net_weight: 50.789,
  },
]

const loopCount = 99
const resultArray0: any = []

for (let i = 0; i < loopCount; i++) {
  originalArray.forEach((item) => {
    resultArray0.push({
      ...item,
      sl_no: resultArray0.length + 1,
      gross_weight: item?.gross_weight + (i + 1),
      tare_weight: item?.tare_weight + (i + 2),
      net_weight: item?.net_weight + (i + 3),
    })
  })
}

// function appendSumAtIntervals(array: any, interval: any) {
//   const result = []
//   let sl_no = 1 // Start the serial number from 1

//   for (let i = 0; i < array?.length; i += interval) {
//     const chunk = array?.slice(i, i + interval)

//     // Append each item in the chunk with incremented sl_no
//     chunk.forEach((item: any) => {
//       item.sl_no = sl_no++ // Increment the sl_no for each item
//       result?.push(item)
//     })

//     // Calculate the sum for the chunk
//     const sumObject = chunk.reduce(
//       (acc: any, item: any) => {
//         acc.gross_weight += item?.gross_weight || 0
//         acc.tare_weight += item?.tare_weight || 0
//         acc.net_weight += item?.net_weight || 0
//         return acc
//       },
//       {
//         sl_no: sl_no++, // Sequence number continues after the chunk
//         gross_weight: 0,
//         tare_weight: 0,
//         net_weight: 0,
//         total: true,
//       }
//     )

//     // Append the sum object after the chunk
//     result.push(sumObject)
//   }

//   return result
// }
function appendSumAtIntervals(array: any[], interval: number) {
  const result = []
  let sl_no = 1 // Start the serial number from 1
  let serial_no = 1 // Serial number for real items only

  for (let i = 0; i < array.length; i += interval) {
    const chunk = array.slice(i, i + interval)

    // Fill remaining items if chunk size is smaller than interval
    if (chunk.length < interval) {
      const missingItems = interval - chunk.length
      for (let j = 0; j < missingItems; j++) {
        chunk.push({
          gross_weight: 0,
          tare_weight: 0,
          net_weight: 0,
          placeholder: true, // Indicate this is a filler item
        })
      }
    }

    // Append each item in the chunk with incremented sl_no and serial_no for real items
    chunk.forEach((item) => {
      item.sl_no = sl_no++ // Increment sl_no for all items (including placeholders)
      if (!item.placeholder) {
        item.serial_no = serial_no++ // Increment serial_no only for real items
      } else {
        item.serial_no = null // Placeholder doesn't get a serial_no
      }
      result.push(item)
    })

    // Calculate the sum for the chunk
    const sumObject = chunk.reduce(
      (acc, item) => {
        acc.gross_weight += Number(item.gross_weight) || 0
        acc.tare_weight += Number(item.tare_weight) || 0
        acc.net_weight += Number(item.net_weight) || 0
        return acc
      },
      {
        gross_weight: 0,
        tare_weight: 0,
        net_weight: 0,
        total: true,
      }
    )

    // Add sl_no to the sum object and append to the result
    sumObject.sl_no = sl_no++ // Increment sl_no for the sum object
    sumObject.serial_no = null // Sum object doesn't get a serial_no
    result.push(sumObject)
  }

  return result
}

const interval = 30

const DispatchWeightPrint = ({
  refDetail,
  dispatchData,
}: {
  refDetail: any
  dispatchData: any
}) => {
  const loopData = (dispatchData?.dispatch_items || [])?.map((el: any) => {
    return {
      sl_no: el?.serial_no,
      gross_weight: el?.gross_weight,
      tare_weight: el?.tare_weight,
      net_weight: el?.net_weight,
    }
  })
  const updatedData = appendSumAtIntervals(loopData, interval)

  const thData = [
    { id: 1, name: 'Sno' },
    { id: 2, name: 'Gross' },
    { id: 3, name: 'Tare' },
    { id: 4, name: 'Net' },
    { id: 5, name: 'Sno' },
    { id: 6, name: 'Gross' },
    { id: 7, name: 'Tar' },
    { id: 8, name: 'Net' },
    { id: 9, name: 'Sno' },
    { id: 10, name: 'Gross' },
    { id: 11, name: 'Tar' },
    { id: 12, name: 'Net' },
  ]

  const totalGrossWeight =
    dispatchData?.dispatch_items
      ?.map((el: any) => Number(el?.gross_weight))
      ?.reduce((acc: any, cur: any) => acc + cur, 0) || 0
  const totalNetWeight =
    dispatchData?.dispatch_items
      ?.map((el: any) => Number(el?.net_weight))
      ?.reduce((acc: any, cur: any) => acc + cur, 0) || 0

  // Generate matrix pattern

  function generateDataMatrix(data: any, rowCount: any) {
    const matrix = []

    for (let i = 0; i < data?.length; i++) {
      const obj: any = {}
      const slNo = data?.[i]

      // First set (current row)
      obj[`${i * 12 + 1}`] =
        data[i]?.gross_weight || 0
          ? slNo?.total
            ? 'Tot'
            : slNo?.serial_no
          : ''
      obj[`${i * 12 + 2}`] = data[i]?.gross_weight
        ? Number(data[i]?.gross_weight).toFixed(3) || '0'
        : 0
      obj[`${i * 12 + 3}`] = data[i]?.gross_weight
        ? Number(data[i].tare_weight).toFixed(3) || '0'
        : 0
      obj[`${i * 12 + 4}`] = data[i]?.gross_weight
        ? Number(data[i]?.net_weight).toFixed(3) || '0'
        : 0
      // obj[`${i * 12 + 5}`] = 'empty'

      // Second set based on rowCount + sl_no
      const secondSlNo = slNo?.sl_no + rowCount
      const secondData = data?.find((item: any) => item.sl_no === secondSlNo)

      obj[`${i * 12 + 5}`] = (secondData ? secondData?.gross_weight : 0)
        ? secondData?.total
          ? 'Tot'
          : secondData?.serial_no
        : ''
      obj[`${i * 12 + 6}`] = secondData?.gross_weight
        ? Number(secondData?.gross_weight).toFixed(3) || '0'
        : 0
      obj[`${i * 12 + 7}`] = secondData?.gross_weight
        ? Number(secondData?.tare_weight).toFixed(3) || '0'
        : 0

      obj[`${i * 12 + 8}`] = secondData?.gross_weight
        ? Number(secondData?.net_weight).toFixed(3) || '0'
        : 0
      // obj[`${i * 12 + 10}`] = 'empty'

      // Third set based on 2 * rowCount + sl_no
      const thirdSlNo = slNo?.sl_no + 2 * rowCount
      const thirdData = data?.find((item: any) => item?.sl_no === thirdSlNo)
      obj[`${i * 12 + 9}`] = (thirdData ? thirdData?.gross_weight : 0)
        ? thirdData?.total
          ? 'Tot'
          : thirdData?.serial_no
        : ''
      obj[`${i * 12 + 10}`] = thirdData?.gross_weight
        ? Number(thirdData?.gross_weight).toFixed(3) || '0'
        : 0
      obj[`${i * 12 + 11}`] = thirdData?.gross_weight
        ? Number(thirdData?.tare_weight).toFixed(3) || '0'
        : 0

      obj[`${i * 12 + 12}`] = thirdData?.gross_weight
        ? Number(thirdData?.net_weight).toFixed(3) || '0'
        : 0

      matrix?.push(obj)

      // Increment i by (rowCount * 2) when we reach a full batch
      if ((i + 1) % rowCount === 0) {
        i += rowCount * 2 // Move to the next batch of data
      }
    }

    return matrix
  }

  // Example usage with custom rowCount
  const result = generateDataMatrix(updatedData, 31) // Set rowCount to 5 for testing

  const getAddress = (addressDetail: any) => {
    const address = addressDetail || {}
    const address1 = address?.address1 || ''
    const address2 = address?.address2 ? `,${address?.address2}` : ''
    const city = address?.city || ''
    const state = address?.state_name ? `${address?.state_name}` : ''
    const pincode = address?.pincode ? `-${address?.pincode}` : ''
    const country = address?.country_name ? `,${address?.country_name}` : ''
    const Address = {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      country: country,
    }
    return Address
  }
  const orgShippingDetails = dispatchData?.organization_address?.find(
    (el: any) => Number(el?.address_type) === Number(1)
  )

  const orgBillingAddressFirst = `${getAddress(orgShippingDetails)?.address1}${
    getAddress(orgShippingDetails)?.address2
  }`
  const orgBillingAddressSecond = `${getAddress(orgShippingDetails)?.city},${
    getAddress(orgShippingDetails)?.state
  }${getAddress(orgShippingDetails)?.pincode}${
    getAddress(orgShippingDetails)?.country
  }`

  return (
    <div ref={refDetail}>
      <table
        className={cn(
          styles.custom_table,
          'bg-white w-100 border border-black text-body-medium fw-medium'
        )}
      >
        <thead>
          <tr>
            <td colSpan={24}>
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex position-relative w-100">
                  <div
                    style={{
                      transform: 'translateY(-50%)',
                      width: 90,
                      height: 90,
                    }}
                    className="d-flex me-12 align-items-center position-absolute top-50 start-0"
                  >
                    {true && (
                      <img
                        src={
                          dispatchData?.organization_logo || businessAvatarFive
                        }
                        alt={'name'}
                        width={90}
                        height={90}
                        className="object-fit-contain"
                      />
                    )}
                  </div>
                  <div className="d-flex w-100 align-items-center justify-content-center">
                    <div className="d-flex  w-100 flex-column align-items-center justify-content-center mt-8">
                      <span className="text-heading-medium text-break fw-bold">
                        {dispatchData?.organization_name}
                      </span>

                      <span
                        style={{ width: '440px' }}
                        className="text-body-small text-center text-truncate text-grey-700 fw-medium"
                      >
                        {`${orgBillingAddressFirst}`}
                      </span>
                      <span
                        style={{ width: '440px' }}
                        className="text-body-small text-center text-truncate text-grey-700 fw-medium"
                      >
                        {`${orgBillingAddressSecond}`}
                      </span>

                      <span className="text-heading-small fw-bold mt-8">
                        {'PACKING LIST'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={24}>
              <div className="d-flex  w-100 flex-column">
                <div className="d-flex w-100   border-opacity-50 border-end-0 border-start-0 border-grey-400 justify-content-between">
                  <div className="border w-50 border-top-0 border-start-0 border-opacity-50  border-grey-400 border-bottom-0 ">
                    <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-top-0 border-grey-400 justify-content-between w-100 py-4">
                      <span className="text-label-small w-25 text-start  fw-semibold">
                        JOB:
                      </span>
                      <span className="text-label-small text-truncate pe-4 fw-semibold">
                        {dispatchData?.job_name}
                      </span>
                    </div>
                    <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0 border-top-0 border-grey-400 w-100 justify-content-between py-4">
                      <span className="text-label-small w-25 text-start fw-semibold">
                        CUSTOMER:
                      </span>
                      <span className="text-label-small text-truncate pe-4 fw-semibold">
                        {dispatchData?.customer_name}
                      </span>
                    </div>
                  </div>

                  <div className=" w-50 border-top-0 border-start-0 border-end-0 border-grey-400 border-bottom-0 ">
                    <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-top-0 border-grey-400 justify-content-between py-4">
                      <span className="text-label-small ps-4 fw-semibold">
                        DISPATCH DOC NO
                      </span>
                      <span className="text-label-small pe-4 fw-semibold">
                        {dispatchData?.dispatch_no}
                      </span>
                    </div>
                    <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0 border-top-0 border-grey-400 justify-content-between py-4">
                      <span className="text-label-small fw-semibold ps-4">
                        DISPATCH DOC DATE
                      </span>
                      <span className="text-label-small fw-semibold pe-4">
                        {dayjs(dispatchData?.dispatch_date).format(
                          'DD,MMM,YYYY'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr style={{ height: '25px' }}>
            {thData?.map((el) => {
              return (
                <>
                  <td colSpan={2}>
                    <span
                      style={{ width: '440px' }}
                      className="text-body-small text-center text-truncate "
                    >
                      {el?.name}
                    </span>
                  </td>
                </>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {result?.map((el: any) => {
            const tdDatas = Object.keys(el)
            return (
              <>
                <tr style={{ height: '25px' }}>
                  {tdDatas?.map((ell: any) => {
                    return (
                      <>
                        <td
                          className={el[ell] === 'Tot' ? 'fw-bolder' : ''}
                          colSpan={2}
                          style={{ maxWidth: '75px', width: '75px' }}
                        >
                          {el[ell] === 'empty'
                            ? ''
                            : el[ell] === true
                            ? 'Tot'
                            : el[ell] || ''}
                          {/* {el[ell]} */}
                        </td>
                      </>
                    )
                  })}
                </tr>
              </>
            )
          })}
        </tbody>
        <tfoot>
          <>
            <tr style={{ height: '32px' }}>
              <td
                colSpan={8}
                className="text-center"
              >{`Total Of Items:${loopData?.length}`}</td>

              <td
                colSpan={8}
                className="text-center"
              >{`Total Gross Weight:${Number(totalGrossWeight).toFixed(
                3
              )}`}</td>

              <td
                colSpan={8}
                className="text-center"
              >{`Total Net Weight:${Number(totalNetWeight).toFixed(3)}`}</td>
            </tr>
          </>

          {/* <tr style={{ height: '32px' }}></tr> */}
          <tr style={{ height: '32px' }}>
            <td className="text-start" colSpan={6}>
              Created By:
            </td>
            <td className="text-start" colSpan={6}>
              Date:
            </td>
            <td className="text-start" colSpan={6}>
              Dispatched By:
            </td>
            <td className="text-start" colSpan={6}>
              Date:
            </td>
          </tr>
          <tr style={{ height: '32px' }}>
            <td colSpan={24} className="text-start text-truncate">
              Notes:{dispatchData?.notes}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default DispatchWeightPrint
